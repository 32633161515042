.article-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  .article-section-heading {
    text-align: center;
    color: rgb(37, 46, 71);
    margin: 20px 0 60px 0;
  }
  .articles {
    display: flex;
    flex-wrap: wrap;
    max-width: 80vw;
    justify-content: center;
    margin-bottom: 20px;
    .placeholder-article {
      width: 464px;
      height: 0px;
    }
    .article {
      display: flex;
      padding: 16px;
      border-radius: 10px;
      cursor: pointer;
      text-decoration: none;
      color: black;
      margin-top: 20px;
      &:hover {
        background-color: rgb(207, 227, 248);
      }
      .icon-container {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: rgb(48, 94, 148);
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .article-icon {
          width: 50px;
          height: 50px;
        }
      }
      .text-container {
        padding: 0 16px;
        width: 300px;
        .title {
          margin: 0 0 16px 0;
        }
      }
    }
  }
  .contact-icon-container {
    display: flex;
    width: 280px;
    align-items: center;
    margin-bottom: 120px;
    .contact-icon {
      display: flex;
      flex: 1 0 0;
    }
  }
}

@media (max-width: 500px) {
  .article-page {
    .articles {
      .placeholder-article {
        width: 0;
      }
      .article {
        .icon-container {
          display: none;
        }
        .text-container {
          width: 100%;
        }
      }
    }
  }
}
