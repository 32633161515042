.hero-page {
  min-height: 75vh;
  width: 100%;
  background-color: #2b77bd;
  background-image: url("./kel4.png"),
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1200 800'%3E%3Cdefs%3E%3CradialGradient id='a' cx='0' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%233b799e'/%3E%3Cstop offset='1' stop-color='%233b799e' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='b' cx='1200' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23283c72'/%3E%3Cstop offset='1' stop-color='%23283c72' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='c' cx='600' cy='0' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%2331405b'/%3E%3Cstop offset='1' stop-color='%2331405b' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='d' cx='600' cy='800' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%232b77bd'/%3E%3Cstop offset='1' stop-color='%232b77bd' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='e' cx='0' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23497382'/%3E%3Cstop offset='1' stop-color='%23497382' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='f' cx='1200' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%231a1a33'/%3E%3Cstop offset='1' stop-color='%231a1a33' stop-opacity='0'/%3E%3C/radialGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='1200' height='800'/%3E%3Crect fill='url(%23b)' width='1200' height='800'/%3E%3Crect fill='url(%23c)' width='1200' height='800'/%3E%3Crect fill='url(%23d)' width='1200' height='800'/%3E%3Crect fill='url(%23e)' width='1200' height='800'/%3E%3Crect fill='url(%23f)' width='1200' height='800'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top right;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .mobile-section {
    margin-top: 100px;
    display: none;
    justify-content: center;
    align-items: center;
    .mobile-section-image {
      background-color: white;
      background-image: url("./kel-mug.png");
      background-size: contain;
      width: 160px;
      height: 160px;
      border-radius: 50%;
    }
  }
  .main-content {
    display: flex;
    .content {
      display: flex;
      width: 50%;
      justify-content: center;
      box-sizing: border-box;
      align-items: center;
      flex-wrap: wrap;
      padding: 60px 24px 24px 24px;
      .text-container {
        color: white;
        //background-color: white;
        padding: 24px;
        border-radius: 8px;
        max-width: 500px;
        h1 {
          font-size: 60px;
          margin-top: 0;
          margin-bottom: 0;
          color: white;
        }
        h2 {
          margin-top: 0;
          font-size: 30px;
          color: white;
        }
        p {
          font-size: 18px;
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  .hero-page {
    .main-content {
      .content {
        .text-container {
          h1 {
            font-size: 54px;
          }
          h2 {
            font-size: 26px;
          }
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .hero-page {
    background: #2b77bd;
    .mobile-section {
      display: flex;
    }
    .main-content {
      .content {
        padding-top: 0;
        width: 100%;
      }
    }
  }
}
