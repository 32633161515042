.app {
  width: 100%;
  .header {
    height: 60px;
    display: flex;
    margin-bottom: 32px;
    justify-content: flex-end;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: white;
    // border-bottom: 2px solid cornflowerblue;
    z-index: 2;
    .navigation {
      display: flex;
      button {
        cursor: pointer;
        text-decoration: none;
        color: rgb(48, 94, 148);
        display: flex;
        height: 100%;
        align-items: center;
        font-weight: bold;
        padding: 0 24px;
        border: none;
        outline: 0;
        background-color: white;
        &:hover,
        &:focus-visible {
          background-color: rgb(21, 40, 63);
          color: white;
        }
        &.active {
          background-color: rgb(48, 94, 148);
          color: white;
        }
      }
    }
  }
  .arrow-section {
    height: 25vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .arrow-icon {
      height: 30%;
      width: auto;
      border-radius: 50%;
      background-color: rgb(48, 94, 148);
      cursor: pointer;
    }
  }
  .photo-link-container {
    display: flex;
    justify-content: flex-end;
    padding: 36px;
    .photo-link {
      color: #5c5c5c;
    }
  }
}
